<template>
  <div class="c-hat gradient--bg white--text">
    <header class="c-hat__header">
      <div class="container d-flex justify-between align-center">
        <a
          :class="{ active: menuOpened }"
          :href="logoUrl"
          class="logo "
          @click.prevent="goHome"
        >
          <img
            v-if="menuOpened"
            loading="lazy"
            src="~/assets/images/logo.svg"
            alt="logo"
          >
          <img
            v-else
            loading="lazy"
            src="~/assets/images/logo-white.svg"
            alt="logo"
          >
          {{ `Домашний интернет ${getAddressText.cityOnlyLoc}` }}
        </a>
        <nav class="c-hat__nav">
          <a
            v-for="link in links"
            :href="link.url"
            :class="{ active: link.url === $route.path }"
            class="c-hat__link white--text"
            @click.prevent="$router.push({ path: link.url })"
          >{{ link.name }}</a>
        </nav>
        <div
          :class="{ 'nav__burger--active': menuOpened }"
          aria-label="mobile menu"
          class="c-hat__burger"
          role="button"
          @click="menuOpened = !menuOpened"
        >
          <span />
        </div>
      </div>
    </header>
    <div
      class="container"
      style="margin-top: 12px"
    >
      <d-breadcrumbs
        :items="crumbsItems"
        :dark="false"
      />
    </div>
    <div class="c-hat__main container relative d-flex flex-column align-start">
      <h1 class="c-hat__title headline">
        Работай специалистом контакт-центра из любой точки России!
      </h1>
      <p class="c-hat__subtitle headline-5">
        Потребуется ноутбук, интернет и желание зарабатывать
      </p>

      <d-btn
        color="primary"
        class="c-hat__btn headline-4"
        @click="scrollToForm"
      >
        Заполнить анкету
      </d-btn>

      <ul class="c-hat__advantages d-flex">
        <li>
          <p class="title">
            Достойная оплата
          </p>
          <p class="title-5">
            до <span class="headline-3">90 000 ₽</span> на руки
          </p>
        </li>
        <li>
          <p class="title">
            График на выбор
          </p>
          <p class="title-5">
            <span class="headline-3">2/2</span> или
            <span class="headline-3">5/2</span>
          </p>
        </li>
        <li>
          <p class="title">
            Оплачиваемое обучение
          </p>
          <p class="title-5">
            <span class="headline-3">5 000 ₽</span> за 5 дней
          </p>
        </li>
      </ul>

      <img
        loading="lazy"
        class="c-hat__img"
        :src="
          imgUrl(
            'https://api.dominter.net/media/uploads/blog/careerHat.png',
            766,
            0,
          )
        "
        alt=""
      >
    </div>
    <LazyVMobileDrawer
      v-model="menuOpened"
      location="top"
      :scrim="false"
      style="z-index: 95 !important"
      class="c-hat__drawer"
      :touchless="true"
      @update:model-value="$emit('close')"
    >
      <ul class="c-hat__mobile-nav">
        <a
          v-for="link in links"
          :href="link.url"
          :class="{ active: link.url === $route.path }"
          class="c-hat__link white--text"
          @click.prevent="$router.push({ path: link.url })"
        >{{ link.name }}</a>
      </ul>
    </LazyVMobileDrawer>
  </div>
</template>

<script setup lang="ts">
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { scrollToBlock } from '~/composible/scrollToBlock'

const ctx = useNuxtApp()
const $router = useRouter()
const tariffsStore = useTariffs()
const getAddressText = computed(() => tariffsStore.getAddressText)
const menuOpened = ref(false)
const logoUrl = computed(() => `/`)
const crumbsItems = computed(() => {
  return [
    {
      to: `/`,
      text: 'home',
    },
    {
      to: `/career/`,
      text: 'Работа в ДомИнтернет',
    },
  ]
})

const links = ref([
  {
    name: 'О проекте',
    url: '/about/',
  },
  {
    name: 'История',
    url: '/about/history/',
  },
  {
    name: 'Контакты',
    url: '/contacts/',
  },
  // {
  //   name: 'Работа',
  //   url: '/career/'
  // },
])

const goHome = () => {
  menuOpened.value = false
  $router.push(logoUrl.value)
}
const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}

const scrollToForm = () => {
  const target = document.getElementById('c-form')
  scrollToBlock(target, 750, 0)
}
</script>

<style scoped lang="scss">
.c-hat {
  padding-bottom: 120px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-bottom: 0;
    margin-bottom: 280px;
  }
  .d-breadcrumbs {
    max-height: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  }
  &__header {
    padding-top: 16px;
    padding-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 22px;
      padding-bottom: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .logo {
      position: relative;
      z-index: 100;
    }
  }

  &__link {
    margin-left: 32px;

    transition: color 0.24s;
    &:hover {
      color: color(gray-light);
    }
    &.active {
      color: color(gray-light);
    }
  }

  &__nav {
    @media (max-width: getBreakpoint(tablet)) {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      max-width: 0;
    }
  }
  &__mobile-nav {
    display: flex;
    flex-direction: column;
    .c-hat__link {
      color: color(black);
      margin-bottom: 24px;
    }
  }

  &:deep(.c-hat__drawer) {
    padding-top: 96px;
    height: 100%;
    min-height: 100%;
  }

  &__main {
    margin-top: 84px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 64px;
    }
  }

  &__title {
    max-width: 550px;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    margin-bottom: 64px;
    position: relative;
    z-index: 1;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 36px;
    }
  }

  &__btn {
    height: 73px;
    border-radius: 40px;
    margin-bottom: 64px;
    padding: 16px 40px;
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);

    @media (max-width: getBreakpoint(tablet)) {
      height: 54px;
      margin-bottom: 32px;
      width: 100%;
      max-width: 400px;
    }
  }

  &__advantages {
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      flex-direction: column;
      order: 2;
      margin-top: -20px;
      margin-bottom: -228px;
    }
    li {
      margin-right: 40px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        border-radius: 12px;
        border: 1px solid color(gray-darker);
        background: color(white);
        box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.15);
        padding: 16px;
        color: color(black);
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__img {
    position: absolute;
    right: -44px;
    top: 7px;
    width: 541px;
    @media (max-width: getBreakpoint(desktop)) {
      width: 350px;
      top: 40px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      position: static;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__burger {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
    z-index: 100;

    @media (max-width: getBreakpoint(tablet)) {
      display: flex;
      width: 40px;
      height: 40px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 36px;
      height: 36px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 32px;
      height: 32px;
    }
    span {
      display: block;
      height: 2px;
      background: color(white);
      transition: background 0.24s;
      width: 30px;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        width: 30px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 26px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 22px;
      }

      &:before,
      &:after {
        content: "";
        left: 0;
        right: 0;
        height: 2px;
        background: color(white);
        position: absolute;
        transition: 0.24s;
        transition-property: transform, top, bottom, background;
      }

      &:before {
        top: -9px;
        @media (max-width: getBreakpoint(tablet)) {
          top: -9px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          top: -7px;
        }
      }

      &:after {
        bottom: -9px;
        @media (max-width: getBreakpoint(tablet)) {
          bottom: -9px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          bottom: -7px;
        }
      }
    }
    &.nav__burger--active {
      span {
        background: transparent;

        &:before {
          top: 0;
          transform: rotate(45deg);
          background: color(black);
        }

        &:after {
          bottom: 0;
          transform: rotate(-45deg);
          background: color(black);
        }
      }
    }
  }
}
</style>
